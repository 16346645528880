<template>
  <div class="container mx-auto py-20 text-center max-w-2xl mdmax:py-10 mdmax:px-4">
    <div class="flex items-center justify-center">
      <ImageItem :source="require('@/assets/images/illustration/success-order.png')" class="w-1/2" v-if="transactionStatus === 'settlement'" />
      <ImageItem :source="require('@/assets/images/illustration/pending-order.png')" class="w-1/2" v-else-if="transactionStatus === 'pending'" />
      <ImageItem :source="require('@/assets/images/illustration/error-order.png')" class="w-1/2" v-else />
    </div>
    <div class="font-bold text-3.5xl mdmax:text-2xl under4inch:text-xl mt-10 mb-5">{{ textContent.main }}</div>
    <div class="mb-5 under4inch:text-sm">
      {{ textContent.note }}
    </div>
    <router-link v-if="transactionStatus === 'settlement'" to="/user/profile" class="text-yellow">Click here to visit profile page.</router-link>
    <router-link v-else to="/" class="text-yellow">Click here to return to Home.</router-link>
  </div>
</template>
<script>
export default {
  name: 'thank-you-order',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  data: () => ({
    email: ''
  }),

  created() {
    if (!this.transactionStatus) this.$router.push('/')
  },

  computed: {
    transactionStatus() {
      return this.$route.query.transaction_status
    },
    textContent() {
      const result = {
        main: '',
        note: ''
      }
      switch (this.transactionStatus) {
        case 'settlement':
          result.main = 'Thank you for purchasing!'
          result.note = 'Your payment has been confirmed. You can access your course via profile page.'
          break
        case 'pending':
          result.main = 'Waiting for payment!'
          result.note = 'Please complete your payment to avoid automatic order cancellation.'
          break
        default:
          result.main = 'Something went wrong...'
          result.note = 'Please contact Customer Service for help, or try again in a few minutes. '
          break
      }
      return result
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped></style>
